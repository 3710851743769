
import './AboutMe.css'

export default function AboutMe() {
    return (
        <div className="AboutMe">
            <div className="about-me-text">
                <p className="first-paragraph">
                    Since my earliest memories, technology has held a unique fascination for me. This life-long enthusiasm for tech
                    eventually channeled me into a fulfilling career in IT, where I spent eight enriching years mastering various roles – ranging from
                    managing a department to fixing printers, and so much more in-between. The diverse hats I wore during this time not only honed my technical acumen,
                    but also cultivated a holistic perspective of the digital world.
                </p>

                <p className="second-paragraph">
                    In 2023, driven by a passion to create and innovate, as well as a long-time desire to learn how to program I graduated from General Assembly's Software
                    Engineering Immersive bootcamp – a rigorous 420-hour journey that solidified my foray into the realm of web development. Though relatively new to the programming scene,
                    I've already accomplished multiple projects using many different technologies, showcasing my swift adaptability and zeal for learning.
                </p>

                <p className="third-paragraph">
                    The MERN stack resonates with me the most, and I consider it my forte. However, my versatility extends beyond it, and I'm ready and willing to leverage other technologies as needed.
                    Today, as I stand at the cusp of a new career chapter, I'm filled with boundless enthusiasm and anticipation. Web development, for me, is more than just a job—it's where passion meets
                    profession, and I'm eager to contribute, learn, and grow in this dynamic field.
                </p> 
            </div>
        </div>
    )
}